// action types
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import {CREATE_ROOM, SET_ROOM_INFO} from "./room.module";

export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const ADMIN_UPDATE_PERSONAL_INFO = "adminUpdateUserAccountInfo";
export const ADMIN_MAKE_USER_ADMIN = "adminMakeUserAdmin";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const GET_ALL_USERS = "getAllUsers";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_ALL_USERS = "setAccountInfo";

// create room
// export const CREATE_ROOM = "createNewRoom";
// export const SET_ROOM_INFO = "setRoomInfo";

const state = {
  user_personal_info: {
    photo: "media/users/300_21.jpg",
    name: "James",
    surname: "Jones",
    company_name: "Fifestudios",
    job: "Application Developer",
    email: "matt@fifestudios.com",
    phone: "44(76)34254578",
    company_site: "fifestudios"
  },
  user_account_info: {
    username: "username_value",
    email: "user_email@domain.com",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  },
  all_users: []
  // room_info: {
  //   name: "Room ONE",
  //   places: 10,
  //   status: 1
  // }
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo;
  },

  allUsers(state) {
    return state.all_users;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.put("/profile/me", payload).then(({ data }) => {
        context.commit(SET_PERSONAL_INFO, payload);
        return data;
      });
    }
  },
  [ADMIN_UPDATE_PERSONAL_INFO](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      //console.log(payload);
      var userUrl = "manager/users/" + payload.id;
      ApiService.put(userUrl, payload).then(({ data }) => {
        // context.commit(SET_PERSONAL_INFO, payload);
        // console.log(data);
        return data;
      });
    }
  },
  [ADMIN_MAKE_USER_ADMIN](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      var idUser = payload[0];
      var url = "manager/users/mark-as-super-admin/" + idUser['id']
      ApiService.post(url, idUser).then(
        ({ data }) => {
          //console.log(data);
          return data;
        }
      );
    }
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  },
  [GET_ALL_USERS](context, payload) {
    return new Promise((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/manager/users?_pp=25", payload).then(({ data }) => {
          let users = data.data;

          Object.entries(users).forEach(item => {
            if (item[1].status === 1) {
              item[1].status = "Activ";
            } else {
              item[1].status = "Inactiv";
            }

            if (item[1].super_admin === true) {
              item[1].super_admin = "Da";
            } else {
              item[1].super_admin = "Nu";
            }
          });

          context.commit(SET_ALL_USERS, users);
          resolve(data); // Let the calling function know that http is done. You may send some data back
          return data;
        });
      } else {
        JwtService.destroyToken();
        reject();
      }
    });
  }
  // [CREATE_ROOM](context, payload) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     ApiService.put("/room/create", payload).then(({ data }) => {
  //       context.commit(SET_ROOM_INFO, payload);
  //       return data;
  //     });
  //   }
  // }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  },
  [SET_ALL_USERS](state, data) {
    state.all_users = data;
  }
  // [SET_ROOM_INFO](state, room_info) {
  //   state.room_info = room_info;
  // }
};

export default {
  state,
  actions,
  mutations,
  getters
};
