import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_PERSONAL_INFO } from "./profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const FORGOT_PASSWORD = "forgotPassword";
export const CONFIRM_REGISTRATION = "confirmRegistration";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  isSuperAdmin: []
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isSuperAdmin(state) {
    return state.isSuperAdmin;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      // console.log(credentials);
      ApiService.post("auth/register", credentials)
        .then(({ data }) => {
          // console.log(data.success);
          // context.commit(SET_AUTH, data);
          resolve(data.success);
        })
        .catch(({ response }) => {
          // console.log(response);
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [CONFIRM_REGISTRATION](context, payload) {
    let url =
      "auth/confirm-registration/" +
      payload.hash +
      "?expires=" +
      payload.expires +
      "&signature=" +
      payload.signature;

    ApiService.get(url)
      .then(() => {
        //console.log("confirm registration: ", data);
      })
      .catch(() => {
        // console.log("error me: ", response);
      });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("profile/me")
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          context.commit(SET_PERSONAL_INFO, data.data);
        })
        .catch(() => {
          // response
          // console.log("error me: ", response);
          // context.commit(SET_ERROR, response.data.errors);
          context.commit(PURGE_AUTH);
        });
    } else {
      // console.log('here');
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("/profile/change-password", password).then(
      ({ data }) => {
        context.commit(SET_PASSWORD, data);
        return data;
      }
    );
  },
  [FORGOT_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.post("/auth/forgot-password", password).then(
      ({ data }) => {
        // context.commit(SET_PASSWORD, data);
        return data;
      }
    );
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.isSuperAdmin = user.data.super_admin;
    // console.log(state.isSuperAdmin);
    // console.log("User: ", state.user);
    state.errors = {};
    if (state.user.data.token) {
      // console.log("token: ", state.user.data.token);
      JwtService.saveToken(state.user.data.token);
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    // console.log("state:", state);
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
