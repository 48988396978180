// action types
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import moment from "moment";

export const GET_ALL_BOOKINGS = "getAllBookings";
export const GET_MY_BOOKINGS = "getMyBookings";
export const DO_BOOKING = "doBooking";
export const CANCEL_BOOKING = "cancelBooking";
export const DOWNLOAD_REPORT = "downloadReport";

// mutation types
export const SET_ALL_BOOKINGS_INFO = "setAllBookingsInfo";
export const SET_MY_BOOKINGS_INFO = "setMyBookingsInfo";

const state = {
  my_bookings: [],
  all_bookings_info: []
};

const getters = {
  currentBookingsInfo(state) {
    return state.my_bookings;
  },
  AllBookingsInfo(state) {
    return state.all_bookings_info;
  }
};

const actions = {
  [GET_ALL_BOOKINGS](context) {
    ApiService.get("manager/bookings")
      .then(({ data }) => {
        //console.log("all bookings: ", data);
        let bookings = data.data;

        //console.log("let bookings: ", bookings);

        Object.entries(bookings).forEach(item => {
          if (item[1].status === 1) {
            item[1].status = "Activa";
          } else {
            item[1].status = "Anulata";
          }
          // date format
          moment.locale("ro");
          item[1].date_start = moment(item[1].date_start)
            .utc()
            .format("DD MMM YYYY");
        });

        context.commit(SET_ALL_BOOKINGS_INFO, bookings);
      })
      .catch(({ response }) => {
        context.commit(response.data.errors);
      });
  },
  [GET_MY_BOOKINGS](context) {
    ApiService.get("profile/bookings")
      .then(({ data }) => {
        // console.log("bookings: ", data);
        context.commit(SET_MY_BOOKINGS_INFO, data);
      })
      .catch(({ response }) => {
        context.commit(response.data.errors);
      });
  },
  [DO_BOOKING](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("booking/new", payload)
        .then(data => {
          //console.log("do booking: ", data);
          // context.commit(DO_BOOKING, data);
          resolve(data);
        })
        .catch(({ response }) => {
          // console.log(response.data.errors.date_finish[0]);
          context.commit(response.data.errors.date_finish[0]);
          reject(response.data.errors.date_finish[0]);
        });
    });
  },
  [CANCEL_BOOKING](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      //console.log(payload.id);
      var bookingId = payload.id;
      var cancelUrl = "booking/cancel/" + bookingId;
      ApiService.put(cancelUrl).then(({ data }) => {
        //console.log(data);
        return data;
      });
    }
  },
  [DOWNLOAD_REPORT](context, payload) {
    if (JwtService.getToken()) {
      var userId = payload[0]["id"];
      var date_from = payload[0]["date_from"];
      var date_to = payload[0]["date_to"];
      var token = JwtService.getToken();
      var startDownloadUrl = "manager/report/bookings?user=" + userId + "&date_from=" + date_from + "&date_to=" + date_to + "&token=" + token;

      window.open("https://api.theone.ro/v1/" + startDownloadUrl);
    }
  }
};

const mutations = {
  [SET_ALL_BOOKINGS_INFO](state, bookings) {
    state.all_bookings_info = bookings;
  },
  [SET_MY_BOOKINGS_INFO](state, bookings) {
    state.my_bookings = bookings;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
