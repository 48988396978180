import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";
import { VERIFY_AUTH } from "./core/services/store/auth.module";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/list-rooms",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/list-rooms",
          name: "list-rooms",
          component: () => import("@/view/pages/List-Rooms.vue")
        },
        {
          path: "/room/:id",
          name: "room",
          component: () => import("@/view/pages/Room.vue"),
          props: true
        },
        {
          path: "bookings",
          name: "bookings",
          component: () => import("@/view/pages/user/bookings.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue")
        },
        {
          path: "/admin",
          name: "admin",
          component: () => import("@/view/pages/admin/Admin.vue"),
          children: [
            {
              path: "add-room",
              name: "add-room",
              component: () => import("@/view/pages/admin/Add-Room.vue")
            },
            {
              path: "add-user",
              name: "add-user",
              component: () => import("@/view/pages/admin/Add-User.vue")
            },
            {
              path: "all-users",
              name: "all-users",
              component: () => import("@/view/pages/admin/All-Users.vue")
            },
            {
              path: "all-bookings",
              name: "all-bookings",
              component: () => import("@/view/pages/admin/All-Bookings.vue"),
              beforeEnter: (to, from, next) => {
                Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => {
                  // console.log(store.getters.isSuperAdmin)
                  if (store.getters.isSuperAdmin == false) {
                    next({ path: "/bookings" });
                  } else {
                    next();
                  }
                });
              }
            }
          ]
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      path: "/auth/confirm-registration/*",
      name: "confirm-registration",
      component: () => import("@/view/pages/auth/login_pages/Login-1")
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
