// action types
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import moment from "moment";

export const CREATE_ROOM = "createNewRoom";
export const GET_ROOMS = "getAllRooms";
export const GET_SINGLE_ROOM = "getRoom";
export const FILTER_ROOMS = "filterRooms";
// export const UPDATE_ROOM = "updateRoom";
// export const UPDATE_ROOM = "updateRoom";

// mutation types
export const SET_ROOM_INFO = "setRoomInfo";
export const SET_ALL_ROOMS_INFO = "setAllRoomsInfo";
export const SET_USERS_ROOMS_INFO = "setAllUsersRoomsInfo";
// export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {
  room_info: {
    name: "Room ONE",
    places: 10,
    status: 1
  },
  all_rooms_info: [],
  users_rooms_info: []
};

const getters = {
  currentRoomInfo(state) {
    return state.room_info;
  },
  AllRoomsInfo(state) {
    return state.all_rooms_info;
  },
  AllUsersRoomsInfo(state) {
    // console.log(state.users_rooms_info);
    // console.log(Object.entries(state.users_rooms_info)
    //   .map(item => item[1])
    //   .filter(item => item.status > 0));

    return state.users_rooms_info;
  }
};

const actions = {
  [CREATE_ROOM](context, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader(true);
      ApiService.post("manager/room/new", payload).then(({ data }) => {
        context.commit(SET_ROOM_INFO, payload);
        return data;
      });
    }
  },
  [GET_ROOMS](context) {
    ApiService.get("rooms" + "?" + "_pp=25")
      .then(({ data }) => {
        // console.log("rooms: ", data);
        context.commit(SET_ALL_ROOMS_INFO, data);
        context.commit(SET_USERS_ROOMS_INFO, data);
      })
      .catch(({ response }) => {
        context.commit(response.data.errors);
      });
  },
  [GET_SINGLE_ROOM](context, payload) {
    var roomVars = payload[0];
    ApiService.get("rooms?id=" + +roomVars.id)
      .then(({ data }) => {
        // console.log("rooms: ", data.data[0]);
        context.commit(SET_ROOM_INFO, data.data[0]);
      })
      .catch(({ response }) => {
        context.commit(response.data.errors);
      });
  },
  [FILTER_ROOMS](context, payload) {
    var paramPlaces = "places[gte]=" + (parseInt(payload.places));
    var paramDateStart = "date_start=" + payload.date_start + " " + payload.time_start;
    var paramDateFinish = "date_finish=" + payload.date_finish + " " + payload.time_finish;

    var filterUrl = "rooms?" + paramPlaces + "&" + paramDateStart + "&" + paramDateFinish;

    ApiService.get(filterUrl)
      .then(({ data }) => {
        //console.log(data);
        context.commit(SET_ALL_ROOMS_INFO, data);
      })
      .catch(({ response }) => {
        context.commit(response.data.errors);
      });
  }
};

const mutations = {
  [SET_ROOM_INFO](state, room_info) {
    state.room_info = room_info;
  },
  [SET_ALL_ROOMS_INFO](state, rooms_info) {
    state.all_rooms_info = rooms_info;
  },
  [SET_USERS_ROOMS_INFO](state, rooms_info) {
    //console.log(rooms_info.data);
    state.users_rooms_info = rooms_info.data;
    //console.log(state.users_rooms_info)
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
